(function($) {
    /**  Form Submissions
    *********************/
  
    jQuery( document ).on( 'cf.form.submit', function ( event, data ) {
      var $form   = data.$form;
      var formId  = $form.attr('id');
      if ( window.cfstate.hasOwnProperty( formId ) ) {
        var state = window.cfstate[formId];
        //log a field's value
        var select = state.getState('fld_8729251_1');
      }
  
      function setFromPage() {
           
        // Current Page Title (first part)
        var title = document.title;
            title = title.split(" |", 1).pop();
  
        // Send GA Event
        return ga('send', 'event', 'Form', 'Submit', select);
      }
      
      setFromPage();

    });
  
    
    /**  Button w/ ID
    *********************/
    // $("#click-call").click(function() {
    //   console.log('Click to call.');
    //   gtag('event', 'call');
    // });

    $("#click-email").click(function() {
      console.log('Click to email.');
      gtag('event', 'generate_lead', { value: 'email' });
    });
    
  })(jQuery);